<template>
    <loading
        v-model:active="isLoading"
        :can-cancel="false"
        :is-full-page="isFullPage"
        :loader="loaderType"
    >
        <div
            v-if="beforeMessage"
            class="before-message"
        >
          <slot name="before">
            {{ beforeMessage }}
          </slot>
        </div>
        <div
            v-if="hasCustomLoader"
            class="default"
        >
            <slot name="custom-loader" />
        </div>
        <div
            v-if="afterMessage"
            class="after-message"
        >
          <slot name="after">
            {{ afterMessage }}
          </slot>
        </div>
    </loading>
</template>

<script>
import 'vue-loading-overlay/dist/css/index.css';
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay';

export default {
    components: {
        Loading
    },
    props: {
        isFullPage: {
            type: Boolean,
            default: true
        },
        loaderType : {
            type: String,
            default : 'dots'
        },
        beforeMessage : {
            type: String,
            default : null
        },
        afterMessage : {
            type: String,
            default : null
        }
    },
    computed: {
        hasCustomLoader () {
            return !!this.$slots['custom-loader']
        },
        ...mapGetters([
            'isLoading'
        ])
    },
}
</script>

<!-- This guy is why your loading message no longer exists -->
<style type="text/css">
    .lds-ellipsis {
        display: none;
    }

    #app{
        display: block;
    }
</style>