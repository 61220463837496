import { DateTime } from 'luxon';
import numeral from 'numeral'
import generateStore from "@/helpers/store";
import config from "@/helpers/config";

let store;

export function isString(value) {
    return typeof value === 'string';
}

export function isSimondsEmail(email) {
    return isString(email) && (
        email.includes('@simonds.com.au') ||
        email.includes('@masterpiecedualoccupancy.com.au') ||
        email.includes('@startpointhomes.com.au') ||
        email.includes('@simondsxpress.com.au')
    );
}

export function isObject (value) {
    return value && typeof value === 'object' && value.constructor === Object;
}

export function formatItemizedErrorMessage(mainMessage, response)
{
    let message = mainMessage + "<br/><br/>";
    // TODO: CLEAN UP
    // if (response.hasOwnProperty('errors')) {
    if (Object.prototype.hasOwnProperty.call(response, "errors")) {
        message += '<ul>';
        for (const key in response.errors) {
            message += "<li>" + response.errors[key] + "</li>";
        }
        message += '</ul>';
    }

    // TODO: CLEAN UP
    // if (response.hasOwnProperty('file')) {
    if (Object.prototype.hasOwnProperty.call(response, "file")) {
        message += response.file + ' ' + response.line + ' ' + response.message;
    }

    // TODO: CLEAN UP
    // if (response.hasOwnProperty('error')) {
    if (Object.prototype.hasOwnProperty.call(response, "error")) {
        message += response.error;
    }

    if (response.trace && getConfig().isDev) {
        message += '<br/><br/>' + [...response.trace].reduce((acc, item, key) => acc + key + ': ' + item.file + ' ' + item.line + ' ' + item.function + '<br/><br/>', '');
    }

    return message;
}

/**
 * this-thing-here => This-thing-here
 * @param str.
 * @returns {string}
 */
export function kebabCaseToWords(str) {
    return str.split('-').map(function (item) {
        return item.charAt(0).toUpperCase() + item.slice(1);
    }).join(' ');
}

export function checkIfAuthorized(next) {
    checkTokenExpiration();
    const store = getStore();
    if(store.getters.isUserLoggedIn) {
        next();
    } else {
        next('/sign-in');
    }
}

export function getStore()
{
    if (!store){
        store = generateStore()
    }
    return store;
}

export function getConfig()
{
    // todo find a better way to do this
    return config;
}

export function getRouter(context)
{
    // todo this shouldnt be needed as in make an store action that just does this.$router CHANGE
    return context.$router;
}

function checkTokenExpiration() {
    const config = getConfig();
    const store = getStore();

    let tokenCreatedTimestamp = store.getters.tokenCreatedTimestamp;
    let tokenExpirationHours = config.tokenExpirationHours;
    let now = DateTime.utc();

    if (!tokenCreatedTimestamp || (DateTime.fromISO(tokenCreatedTimestamp, {zone: 'UTC'}).plus({hours: tokenExpirationHours}) < now)) {
        store.commit('updateUser', null);
    }
}

export function isArray (value) {
    return value && typeof value === 'object' && value.constructor === Array;
}

export function isArrayEmpty (value) {
    return isArray(value) && (value.length === 0);
}

export function sortByKey(unordered) {
    let ordered = {};
    Object.keys(unordered).sort().forEach(function(key) {
        ordered[key] = unordered[key];
    });
    return ordered;
}

export function utcTimestampToLocalTime(timestamp, timezone) {
    return DateTime.fromSeconds(timestamp, {zone: timezone}).setZone('local');
}

export function utcTimestampToLocalRelativeDate(timestamp, timezone) {
    return utcTimestampToLocalTime(timestamp, timezone).toRelative();
}

export function utcTimestampToLocalPrettyDate(timestamp, timezone) {
    let localTime = utcTimestampToLocalTime(timestamp, timezone);
    let diff = localTime.diffNow(['weeks', 'days']).toObject();
    let result = '';
    if (diff.weeks <= -1) {
        result = localTime.toFormat('dd/LL/yyyy') + ' at ' + localTime.toFormat('hh:mm a');
    } else if (diff.days <= -1) {
        result = localTime.toRelative() + ' at ' + localTime.toFormat('hh:mm a');
    } else {
        result = localTime.toRelative();
    }
    return result;
}

export function numberToString(number,format) {
    return numeral(number).format(format)
}

export function formatPrice(price) {
    return numberToString(price,'0,0.00');
}

export function validPhone(phone) {
    // 0x 1234 5678 || 61 8 1234 5678 || +61 04 1234 5678 (without spaces)
    const patternTelephone = /^((\+)?61)?(0?[1-9]\d{8})$/;
    return patternTelephone.test(phone);
}

export function log(...values) {
    if (this.getConfig().isDev) {
        console.log(values);
    }
}

export function snakeToCamel(str) {
    return str.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase());
}
export function isDeviceSize(self, size) {
    return self.$mq === size;
}