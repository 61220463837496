const processEnv = process.env;
const port = typeof processEnv.VUE_APP_PORT !== 'undefined' ? processEnv.VUE_APP_PORT : null;
const sentrySever = typeof processEnv.VUE_APP_SENTRY_SERVER !== 'undefined' ? processEnv.VUE_APP_SENTRY_SERVER : 'sentry.simplisite.com.au'
const sentryRelease = typeof processEnv.VUE_APP_SENTRY_RELEASE !== 'undefined' ? processEnv.VUE_APP_SENTRY_RELEASE : null
const allowedPermissions = typeof processEnv.VUE_APP_ALLOWED_PERMISSIONS !== 'undefined' ? processEnv.VUE_APP_ALLOWED_PERMISSIONS.split(",") : null
const useBearer = typeof processEnv.VUE_APP_USE_BEARER !== 'undefined' ? processEnv.VUE_APP_USE_BEARER === "true" : true
const scope = typeof processEnv.VUE_APP_SCOPE !== 'undefined' ? processEnv.VUE_APP_SCOPE : null
const webpackDevSilent = typeof processEnv.WEBPACK_DEV_SILENT !== 'undefined' ? processEnv.WEBPACK_DEV_SILENT : false

const config = {
    apiUrl: processEnv.VUE_APP_BACKEND_URL + "/api",
    backendUrl: processEnv.VUE_APP_BACKEND_URL,
    isDev: processEnv.NODE_ENV === 'development',
    environment: processEnv.NODE_ENV,
    sentry: {
        server: sentrySever,
        key: processEnv.VUE_APP_SENTRY_DSN_JS,
        id: processEnv.VUE_APP_SENTRY_PROJECT_ID,
        releaseID: sentryRelease
    },
    port,
    appVersion: require('../../package.json').version,
    gtmID: processEnv.VUE_APP_GTM_ID,
    azure: {
        clientID: processEnv.VUE_APP_AZURE_CLIENT_ID,
        tenant: processEnv.VUE_APP_AZURE_TENANT
    },
    allowedPermissions,
    scope,
    enableSSO: processEnv.VUE_APP_ENABLE_SSO === "true",
    useBearer,
    webpackDevSilent,
    title: processEnv.VUE_APP_TITLE,
    vueAppEnvironment:processEnv.VUE_APP_ENVIRONMENT,
    vueAppVersion: processEnv.VUE_APP_VERSION
}

config.apiClientID = processEnv.VUE_APP_API_CLIENT_ID;
config.apiClientSecret=  processEnv.VUE_APP_API_CLIENT_SECRET;
config.appName = 'discounts';
config.tokenExpirationHours = 12;
export default config;
