<template>
    <div v-if="detailsLoaded" class="w-full max-w-3xl mx-auto px-6 py-6 lg:px-8">
        <div class="print:hidden pb-4">
            <a href="/dashboard" class="flex items-center gap-1 text-sm underline underline-offset-4 decoration-transparent hover:decoration-inherit duration-300 transition-all ease-in-out">
                <ArrowLeftIcon class="h-4 w-4 flex-shrink-0" aria-hidden="true" />
                <span>Return to Dashboard</span>
            </a>
        </div>
        <section v-if="showBonusView" class="relative px-6 py-6 lg:px-8 bg-white rounded-md">
            <object v-if="details.status === 'draft'" class="w-4/5 h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0" type="image/svg+xml" :data="draftText"></object>
            <object v-else-if="details.status === 'approved'" class="w-4/5 h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0" type="image/svg+xml" :data="approvedText"></object>
            <object v-else-if="details.status === 'submitted'" class="w-4/5 h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0" type="image/svg+xml" :data="submittedText"></object>
            <object v-else-if="details.status === 'rejected'" class="w-4/5 h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0" type="image/svg+xml" :data="rejectedText"></object>

            <div class="flex flex-col gap-4 relative z-1">
                <div class="py-4 flex items-center justify-between gap-4 flex-wrap">
                    <div class="flex flex-col gap-2">
                        <h2 class="text-xl font-semibold text-gray-900"> {{ detailsForDisplay.customerName }}</h2>
                        <p v-if="details.jobNumber">Job #{{ details.jobNumber }}</p>
                        <p v-else>No Job ID Confirmed</p>
                    </div>
                    <div class="flex gap-2 items-center">
                        <button v-if="showEditSubmitButtons" @click="goToEdit" type="button" class="inline-flex gap-2 items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">
                            <PencilSquareIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span>Edit</span>
                        </button>
                        <button v-if="showCancelButtons" @click="cancel" type="button" class="inline-flex gap-2 items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">
                            <TrashIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span>Cancel</span>
                        </button>
                        <button v-if="!showApproveRejectButtons" @click="printWindow()" type="button" class="print:hidden inline-flex items-center justify-center rounded-md border border-transparent bg-red-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brightness-110 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none gap-2">
                            <PrinterIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span>Print</span>
                        </button>
                    </div>
                </div>

                <div v-if="isReviewed && details.approverNotes" class="text-gray-600 -mt-4">
                    <p class="text-sm font-semibold">Approver Notes</p>
                    <p>{{ details.approverNotes }}</p>
                </div>

                <div class="print:hidden h-px bg-black">
                </div>

                <div class="flex items-center justify-between gap-4 flex-wrap">
                    <h3 class="text-xl font-semibold">Customer Information</h3>
                    <div>
                      <p class="text-sm font-semibold text-right">Status</p>
                      <status-badge :status="details.status"/>
                    </div>
                </div>

                <div class="print:hidden h-px bg-black">
                </div>

                <div v-for="(value, key) in detailsForDisplay" :key="key">
                    <p class="text-sm font-semibold">{{ labels[key] ?? key }}</p>
                    <p>{{ value }}</p>
                </div>

                <template v-for="(value, key) in discountData" :key="key">
                    <div v-if="labels.hasOwnProperty(key)">
                        <p class="text-sm font-semibold">{{labels[key] }}</p>
                        <p>{{ getValue(key, value)  }}</p>
                    </div>
                </template>

                <div>
                    <p class="text-sm font-semibold">Request Notes</p>
                    <p>{{ details.requestNotes ?? "N/A"}}</p>
                </div>

                <div class="print:hidden h-px bg-black">
                </div>

                <div>
                    <p>Total Bonus</p>
                    <p class="text-xl font-semibold text-gray-900"> {{ totalBonus }}</p>
                </div>

                <div class="print:hidden h-px bg-black">
                </div>

                <div v-if="showApproveRejectButtons" class="flex flex-col gap-4">
                    <div>
                        <textarea v-model="notes" rows="3" class="w-full flex p-4 w-full min-h-[48px] border border-gray-200 focus:border-gray-300 focus:ring-0" aria-label="Notes" placeholder="Enter Notes"></textarea>
                    </div>
                    <div class="flex gap-2 items-start">
                        <button @click="approve" type="button" class="max-h-[48px] inline-flex gap-1 items-center justify-center rounded-md border border-transparent bg-red-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brightness-110 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">
                            <CheckCircleIcon class="h-5 w-auto flex-shrink-0 -ml-1" aria-hidden="true" />
                            <span>Approve Application</span>
                        </button>
                        <button @click="reject" type="button" class="max-h-[48px] inline-flex gap-1 items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">
                            <XCircleIcon class="h-5 w-auto flex-shrink-0 -ml-1" aria-hidden="true" />
                            <span>Reject Application</span>
                        </button>
                    </div>
                </div>

                <div class="flex gap-2 items-center">
                    <button v-if="showEditSubmitButtons" @click="submit" type="button" class="max-h-[48px] inline-flex gap-2 items-center justify-center rounded-md border border-transparent bg-red-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brightness-110 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">
                        <span>Submit For Approval</span>
                        <PaperAirplaneIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    </button>
                </div>
            </div>
        </section>
        <section v-else>
            <div class="overflow-hidden bg-white shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:px-6 flex items-center justify-between gap-4 flex-wrap">
                    <div>
                        <h3 class="text-lg font-medium leading-6 text-gray-900">Customer Information</h3>
                        <p v-if="details.jobNumber" class="mt-1 max-w-2xl text-sm text-gray-500">Job #{{ details.jobNumber }}</p>
                        <p v-else class="mt-1 max-w-2xl text-sm text-gray-500">No Job ID Confirmed</p>
                    </div>
                    <div class="flex gap-2 items-center">
                        <button v-if="showApproveRejectButtons" @click="approve" type="button" class="inline-flex items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">Approve</button>
                        <button v-if="showApproveRejectButtons" @click="reject" type="button" class="inline-flex items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">Reject</button>
                        <button v-if="showEditSubmitButtons" @click="goToEdit" type="button" class="inline-flex items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">Edit</button>
                        <button v-if="showEditSubmitButtons" @click="submit" type="button" class="max-h-[48px] inline-flex items-center justify-center rounded-md border border-transparent bg-red-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brightness-110 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">Submit For Approval</button>
                    </div>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl class="sm:divide-y sm:divide-gray-200">
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Application for</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ details.discountType }}</dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Discount Amount</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">${{ amount }}</dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Discount Notes</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ details.requestNotes }}</dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Full Name</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ details.customer.name }}</dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Email Address</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ details.customer.email }}</dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Phone Number</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{{ details.customer.phone }}</dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Status</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <status-badge :status="details.status"/>
                            </dd>
                        </div>
                        <div class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt class="text-sm font-medium text-gray-500">Print Application</dt>
                            <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <button @click="printWindow()" type="button" class="inline-flex items-center justify-center rounded-md border border-transparent bg-red-brand px-4 py-2 text-sm font-medium text-white shadow-sm hover:brightness-110 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto gap-2">
                                    <PrinterIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                                    <span>Print</span>
                                </button>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </section>
    </div>
    <div v-if="loadFailed" class="w-full max-w-3xl m-auto gap-4 px-6 py-6 lg:px-8 py-8 lg:py-12 flex flex-col items-center justify-center flex-grow">
        <h1 class="text-4xl font-semibold text-gray-900 text-center">
            404
        </h1>
        <object class="w-48 h-auto pointer-events-none -my-4" type="image/svg+xml" :data="animated404"></object>
        <h2 class="text-xl font-semibold text-gray-900 text-center">
            The page you are looking for is not found.
        </h2>
    </div>
</template>

<script>
import { PrinterIcon, PencilSquareIcon, TrashIcon, PaperAirplaneIcon, ArrowLeftIcon, XCircleIcon, CheckCircleIcon } from '@heroicons/vue/20/solid';
import { mapGetters } from 'vuex';
import router from '../../helpers/router';
import { getDiscountRequest, approveRequest, rejectRequest, submitRequest, deleteDiscountRequest } from '@/helpers/api';
import StatusBadge from "../generic/StatusBadge";
import { formatPrice } from "@/helpers/helpers";

export default {
    name: 'DiscountRequestDetails',
    components: {
        PrinterIcon, StatusBadge, PencilSquareIcon, TrashIcon, PaperAirplaneIcon, ArrowLeftIcon, XCircleIcon, CheckCircleIcon
    },
    data: function() {
        return {
            details: {},
            detailsForDisplay: null,
            detailsLoaded: false,
            loadFailed: false,
            useBonusLayout: true,
            discountData: {},
            notes: "",
            labels: {
                'customerName': "Customer's Name",
                'customerPhone': "Customer's Phone Number",
                'customerEmail': "Customer's Email",
                'range': 'What house type are they purchasing?',
                'journey': 'What type of journey are they on?',
                'discountType': 'What type of discount are you applying?',
                'forecasted_deposit_date': 'When is the forecasted deposit date?',
                'previous_purchases_count': 'How many purchases have they made before?',
                'previous_job_ids': 'Previous Job IDs'
            },
            draftText: require('@/assets/svg/text-angled-draft.svg'),
            approvedText: require('@/assets/svg/text-angled-approved.svg'),
            rejectedText: require('@/assets/svg/text-angled-rejected.svg'),
            submittedText: require('@/assets/svg/text-angled-submitted.svg'),
            animated404: require('@/assets/svg/animated-404.svg'),
        }
    },
    computed: {
        ...mapGetters([
            'isUserManager',
            'isUserConsultant',
            'serverTimezone'
        ]),
        isManager() {
            return this.isUserManager;
        },
        isConsultant() {
            return this.isUserConsultant;
        },
        showBonusView() {
            // To finalize what layout to use
            return this.useBonusLayout;
        },
        isReviewed() {
            return ['approved', 'rejected'].includes(this.details.status);
        },
        showApproveRejectButtons() {
            return this.isManager && !this.isReviewed;
        },
        showCancelButtons() {
            return this.isConsultant && !this.isReviewed;
        },
        showEditSubmitButtons() {
            console.log('status: ' + this.details.status);
            return this.isConsultant && this.details.status === 'draft';
        },
        totalBonus() {
            if (this.isReviewed || this.isManager || this.details.discountType === 'Gift Card')
            {
                return "$" + this.amount;
            } else {
                return "To be approved by Manager";
            }
        },
        amount() {
            return formatPrice(this.details.amount);
        }
    },
    mounted() {
        this.getDiscountsReq(this.$route.params.id);
    },
    methods: {
        printWindow() {
            window.print();
        },
        goToEdit() {
            router.push({
                name: 'Edit',
                params: { id: this.details.id }
            });
        },
        getDiscountsReq(requestID) {
            getDiscountRequest(requestID).then((response) => {
                if ((response.status === 200)) {
                    let body = response.body;
                    this.details = response.body;

                    let detailsForDisplay = {
                        customerName: body.customer.name,
                        customerPhone: body.customer.phone,
                        customerEmail: body.customer.email,
                        range: body.range.title,
                        journey: body.journey ? body.journey.title : 'N/A',
                        discountType: body.discountType
                    };

                    this.discountData = body.discountData;
                    this.detailsForDisplay = detailsForDisplay;
                    this.detailsLoaded = true;

                    return;
                }

                this.loadFailed = true;
            }).catch(() => {
                this.loadFailed = true;
            });
        },
        submit() {
            // TODO use modal
            if (confirm('Are you sure you want to submit this discount request?')) {
                submitRequest(this.details.id).then((response) => {
                    if ((response.status === 200)) {
                        this.details = response.body;
                    }
                });
            }
        },
        approve() {
            // TODO use modal
            if (confirm('Are you sure you want to approve this discount request?')) {
                let params = {approver_notes: this.notes};
                approveRequest(this.details.id, params).then((response) => {
                    if ((response.status === 200)) {
                        this.details = response.body;
                    }
                });
            }
        },
        reject() {
            // TODO use modal
            if (confirm('Are you sure you want to reject this discount request?')) {
                let params = {approver_notes: this.notes};
                rejectRequest(this.details.id, params).then((response) => {
                    if ((response.status === 200)) {
                        this.details = response.body;
                    }
                });
            }
        },
        cancel() {
            // TODO use modal
            if (confirm('Are you sure you want to cancel this discount request?')) {
                deleteDiscountRequest(this.details.id).then((response) => {
                    if ((response.status === 200)) {
                        router.push({
                            name: 'Dashboard',
                        });
                    }
                });
            }
        },
        getValue(key, value) {
            if (key === 'forecasted_deposit_date') {
                let date = new Date(value);
                value = date.toLocaleString('default', {
                    timeZone: this.serverTimezone,
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                });
            } else if (key === 'previous_job_ids') {
                value = value.join(", ");
            }
            return value;
        }
    }
}
</script>