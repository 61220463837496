<template>
    <footer class="w-full max-w-8xl mx-auto py-6 px-6 lg:px-8 flex items-center justify-between flex-wrap gap-4 text-sm">
        <p>
            &copy; {{ currentYear }} Simonds Group Limited - CDB-U 49491
        </p>
        <p>
            version: {{ versionNumber }}
        </p>
    </footer>
</template>
<script>
import config from '../../helpers/config'

export default {
    computed: {
        versionNumber: function () {
            return config.appVersion
        },
        currentYear: function () {
            return new Date().getFullYear();
        }
    }
}
</script>
