<template>
    <div class="flex flex-col min-h-screen w-full">
        <loading-overlay />
        <site-nav class="print:hidden" />
        <router-view v-slot="{ Component }" :key="$route.path">
            <transition
                name="fade"
                mode="out-in"
                appear
            >
                <component :is="Component" />
            </transition>
        </router-view>
        <site-footer class="print:hidden" />
    </div>
</template>

<script>
import SiteFooter from "./core/SiteFooter";
import LoadingOverlay from "@/components/pages/LoadingOverlay";
import SiteNav from "./core/SiteNav";

export default {
  components: { SiteFooter, LoadingOverlay, SiteNav }
};
</script>

<style lang="scss">
// TODO: CLEAN UP CONTENTS
@import "../scss/main.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
