import {createRouter as VueRouterCreateRouter, createWebHistory} from 'vue-router'
import { checkIfAuthorized, getStore, isObject } from './helpers';
import { appLogout } from './api';
import ViewDiscount from "@/components/pages/ViewDiscount";
const SignIn = () => import( '../views/SignIn.vue')
const Dashboard = () => import('../views/Dashboard.vue')
const Create = () => import( '../views/Create.vue')
const NotFound = () => import('../views/NotFound')

function createRouter(routes = [{path: '*', name: 'Not Found', component: NotFound}], options = {}) {
    if (!routes.find(route => route.name === 'Not Found')) {
        routes.push({
            path: "/:catchAll(.*)",
            name: "NotFound",
            component: NotFound,
            meta: {
                requiresAuth: false
            }
        })
    }

    let routerOptions = {
        history: createWebHistory(),
        scrollBehavior() {
            return { x: 0, y: 0 };
        },
        routes: routes,
    };

    if (options && isObject(options)) {
        routerOptions = {
            ...routerOptions,
            ...options
        }
    }

    return VueRouterCreateRouter(routerOptions);
}

const router = createRouter([
    {
        path: '/',
        redirect: "/dashboard"
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            checkIfAuthorized(next);
        }
    },
    {
        path: '/logout',
        name: 'Logout',
        beforeEnter: (to, from, next) => {
            appLogout().then((response) => {
                const store = getStore();
                store.commit('updateUser', null);

                if (response.status === 200) {
                    store.commit('updateAPIAccessToken', response.body.accessToken);
                }

                next('/sign-in');
            });
        }
    },
    {
        path: '/sign-in',
        name: 'Sign In',
        component: SignIn,
        beforeEnter: (to, from, next) => {
            const store = getStore();

            if(store.getters.isUserLoggedIn) {
                next('/dashboard');
            } else {
                next();
            }
        }
    },
    {
        path: '/create',
        name: 'Create',
        component: Create,
        beforeEnter: (to, from, next) => {
            checkIfAuthorized(next);
        }
    },
    {
        path: '/edit/:id',
        name: 'Edit',
        component: Create,
        beforeEnter: (to, from, next) => {
            checkIfAuthorized(next);
        }
    },
    {
        path: '/view/:id',
        name: 'ViewDiscount',
        component: ViewDiscount,
        beforeEnter: (to, from, next) => {
            const store = getStore();
            store.commit('updateTargetLink', to.path);
            store.commit('updateTargetQuery', to.query);
            checkIfAuthorized(next);
        }
    },
]);
export default router;
