import App from './App'
import router from './helpers/router';
import config from './helpers/config';
import './scss/main.scss';
import {createApp} from "vue";
import {Vue3Mq} from "vue3-mq";
import {getStore} from "./helpers/helpers";
import {initSentry} from "./helpers/sentry";

require('./helpers/dependencies');

const store = getStore();
const app = createApp(App);
initSentry(app, router, config);

app.use(store).use(router).use(Vue3Mq, {
    breakpoints: { // default breakpoints - customize this
        xsmall: 0,
        small: 640,
        medium: 960,
        large: 1200,
        xlarge: 1600,
    },
}).mount('#app');

window.app = app;