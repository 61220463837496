import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import config from './config'

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
let persistState = (state) => ({
    user: state.user,
    apiAccessToken: state.apiAccessToken,
    isLoggedInAzure: state.isLoggedInAzure,
    serverTimezone: state.serverTimezone,
    tokenCreatedTimestamp: state.tokenCreatedTimestamp,
});

function generateBaseStoreConfig(config,persistState) {
    let persistStatePlugin = [
        createPersistedState({
            reducer: persistState,
            getState: (key) => JSON.parse(localStorage.getItem(key)),
            setState: (key, state) => localStorage.setItem(key,JSON.stringify(state))
        })
    ];
    // TODO: CLEAN UP
    let plugins = config.isDev ? persistStatePlugin.concat([createLogger()]) : persistStatePlugin;

    return {
        plugins: plugins,
        state: {
            user: null,
            apiAccessToken: null,
            isLoadingCount: 0,
            error: {
                status: 200,
                message: ""
            },
            clientNotice: {
                message: '',
                title: '',
            },
            isLoggedInAzure: false,
            notification: "",
        },
        actions: {
            updateAPIAccessToken(context, accessToken) {
                context.commit("updateAPIAccessToken", accessToken);
            },
            updateUser(context, user) {
                context.commit("updateUser", user);
            },
            isLoading (context, isLoading) {
                context.commit("updateIsLoading",isLoading);
            },
            updateError (context, error) {
                context.commit("updateError",error);
            },
            updateNotification (context, notification) {
                context.commit("updateNotification", notification);
            },
            updateIsLoggedInAzure (context, flag) {
                context.commit("updateIsLoggedInAzure", flag);
            },
            updateClientNoticeTitle (context, title) {
                context.commit("updateClientNoticeTitle", title);
            },
            updateClientNoticeMessage (context, message) {
                context.commit("updateClientNoticeMessage", message);
            },
        },
        getters: {
            user: (state) => (state.user),
            apiAccessToken: (state) => (state.apiAccessToken),
            apiToken: (state, getters) => {
                return getters.user ? getters.user.apiToken : null;
            },
            isUserLoggedIn: (state, getters) => {
                return (getters.user !== null);
            },
            isLoading: (state) => (state.isLoadingCount > 0),
            error: (state) => (state.error),
            errorMessage: (state, getters) => (getters.error.message),
            errorStatus: (state, getters) => (getters.error.status),
            notification: (state) => (state.notification),
            isLoggedInAzure: (state) => (state.isLoggedInAzure),

            clientNoticeMessage: (state) => (state.clientNotice.message),
            clientNoticeTitle: (state) => (state.clientNotice.title),
        },
        mutations: {
            updateAPIAccessToken(state, apiAccessToken) {
                state.apiAccessToken = apiAccessToken;
            },
            updateUser(state, user) {
                state.user = user;
            },
            updateIsLoading(state, changeAmount) {
                state.isLoadingCount = state.isLoadingCount + changeAmount;
            },
            updateError(state, error) {
                let errorStatus = 200;
                let errorMessage = '';

                if (error) {
                    // TODO: CLEAN UP
                    // if (error.hasOwnProperty('status')) {
                    if (Object.prototype.hasOwnProperty.call(error, "status")) {
                        errorStatus = error.status;
                    }

                    // TODO: CLEAN UP
                    // if (error.hasOwnProperty('message')) {
                    if (Object.prototype.hasOwnProperty.call(error, "message")) {
                        errorMessage = error.message;
                    }
                }

                state.error = {
                    status: errorStatus,
                    message: errorMessage
                };
            },
            updateClientNoticeTitle(state, title) {
                state.clientNotice.title = title;
            },
            updateClientNoticeMessage(state, message) {
                state.clientNotice.message = message;
            },
            updateNotification(state, notification) {
                state.notification = notification;
            },
            updateIsLoggedInAzure(state, flag) {
                state.isLoggedInAzure = flag;
            },
        }
    };
}

let storeConfig = generateBaseStoreConfig(config,persistState);
storeConfig.state = {
    ...storeConfig.state, ...{
        isLoadingCount: 0,
        user: null,
        error: {
            status: 200,
            message: ""
        },
        pagination: {
            currentPage: 1,
            lastPage: 1,
            total: 0,
            count: 0,
            perPage: 0,
            firstItem: 1,
            lastItem: 1,
        },
        itemsPerPage: 15,
        activePage: "index",
        apiAccessToken: null,
        serverTimezone: null,
        tokenCreatedTimestamp: null,
        targetLink: null,
        targetQuery: null,
        appId: null,
        requestOptions: {}
    }
};

storeConfig.actions = {
    ...storeConfig.actions, ...{
        updatePagination(context, pagination) {
            context.commit("updatePagination", pagination);
        },
        updateActivePage(context, page) {
            context.commit("updateActivePage", page);
        },
        updateServerTimezone(context, value) {
            context.commit("updateServerTimezone", value);
        },
        updateTokenCreatedTimestamp(context, value) {
            context.commit("updateTokenCreatedTimestamp", value);
        },
        updateTargetLink(context, value) {
            context.commit("updateTargetLink", value);
        },
        updateTargetQuery(context, value) {
            context.commit("updateTargetQuery", value);
        },
        updateAppId(context, appId) {
            context.commit("updateAppId", appId);
        },
        updateRequestOptions(context, value) {
            context.commit("updateRequestOptions", value);
        }
    }
};

storeConfig.getters = {
    ...storeConfig.getters, ...{
        pagination: (state) => (state.pagination),
        itemsPerPage: (state) => (state.itemsPerPage),
        activePage: (state) => (state.activePage),
        serverTimezone: (state) => (state.serverTimezone),
        isUserAdmin: (state, getters) => {
            return getters.user && (getters.user.permissions && getters.user.permissions.includes('admin'));
        },
        canUserAccessDiscounts: (state, getters) => {
            return getters.user && (getters.user.permissions
                && (
                    getters.user.permissions.includes('read-discount-requests')
                    || getters.user.permissions.includes('access-own-discount-requests')
                    || getters.user.permissions.includes('access-subordinate-discount-requests')
                    || getters.user.permissions.includes('admin')
                )
            );
        },
        tokenCreatedTimestamp: (state) => (state.tokenCreatedTimestamp),
        targetLink: (state) => (state.targetLink),
        targetQuery: (state) => (state.targetQuery),
        canUserAccessSettings: (state, getters) => {
            return getters.isUserAdmin;
        },
        appId: (state) => (state.appId),
        isUserConsultant(state, getters) {
            return getters.user && (getters.user.permissions && getters.user.permissions.includes('access-own-discount-requests'));
        },
        isUserManager(state, getters) {
            return getters.user && (getters.user.permissions && getters.user.permissions.includes('access-subordinate-discount-requests'));
        },
        isUserStaff(state, getters) {
            return getters.user && (getters.user.permissions && getters.user.permissions.includes('read-discount-requests'));
        },
        rangeOptions(state) {
            return state.requestOptions && Object.prototype.hasOwnProperty.call(state.requestOptions, 'ranges') ? state.requestOptions.ranges : [];
        },
        journeyOptions(state) {
            return state.requestOptions && Object.prototype.hasOwnProperty.call(state.requestOptions, 'journeys') ? state.requestOptions.journeys : [];
        },
        regionOptions(state) {
            return state.requestOptions && Object.prototype.hasOwnProperty.call(state.requestOptions, 'regions') ? state.requestOptions.regions : [];
        },
        discountTypes(state) {
            return state.requestOptions && Object.prototype.hasOwnProperty.call(state.requestOptions, 'discountTypes') ? state.requestOptions.discountTypes : [];
        }
    }
};

storeConfig.mutations = {
    ...storeConfig.mutations, ...{
        updatePagination(state, pagination) {
            state.pagination = pagination
        },
        updateActivePage(state, page) {
            state.activePage = page
        },
        updateServerTimezone(state, value) {
            state.serverTimezone = value
        },
        updateTokenCreatedTimestamp(state, value) {
            state.tokenCreatedTimestamp = value
        },
        updateTargetLink(state, value) {
            state.targetLink = value
        },
        updateTargetQuery(state, value) {
            state.targetQuery = value
        },
        updateAppId(state, value) {
            state.appId = value
        },
        updateRequestOptions(state, value) {
            state.requestOptions = value;
        }
    }
};

export default () => createStore(storeConfig);