<template>
    <div class="bg-gray-100 w-full flex flex-grow">
        <discount-request-details />
    </div>
</template>

<script>
import router from '../../helpers/router'
import DiscountRequestDetails from "@/components/panels/DiscountRequestDetails";

export default {
    name: 'ViewDiscount',
    components: {
        DiscountRequestDetails
    },
    methods: {
        goToIndex() {
            router.push({
                name: 'Dashboard',
            });
        }
    },
}
</script>

<style>
</style>