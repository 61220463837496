<template>
    <span>
        <slot :logout="logout">
            <button
                type="button"
                class="uk-button uk-button-primary"
                @click="logout"
            >
                Logout
            </button>
        </slot>
    </span>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        redirectRouteName: {
            type: String,
            default: () => "Logout"
        },
    },
    computed: {
        ...mapGetters({
            isLoggedInAzure: 'isLoggedInAzure',
        }),
    },
    methods: {
        ...mapActions({
            updateUser: 'updateUser',
            updateTargetLink: 'updateTargetLink',
            updateTargetQuery: 'updateTargetQuery',
            updateIsLoggedInAzure: 'updateIsLoggedInAzure'
        }),
        logout() {
            this.updateUser(null);
            this.updateTargetLink(null);
            this.updateTargetQuery(null);
            this.$emit('onLogout');
            if (this.isLoggedInAzure) {
                let redirectLink = this.$router.resolve({ name: this.redirectRouteName });
                let uri = encodeURI(window.location.origin + redirectLink.href);
                this.updateIsLoggedInAzure(false);
                window.location.href = "https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=" + uri;
            } else {
                this.$router.push({ name: this.redirectRouteName })
            }
        }
    }
}
</script>
