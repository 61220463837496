<template>
    <p v-if="status === 'draft'" class="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">Draft</p>
    <p v-if="status === 'approved'" class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">Approved</p>
    <p v-if="status === 'submitted'" class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">Submitted</p>
    <p v-if="status === 'rejected'" class="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">Rejected</p>
</template>

<script>
export default {
    name: "StatusBadge",
    props: {
        status: {
            type: String,
            required: true
        }
    },
}
</script>

<style scoped>

</style>