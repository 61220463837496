<template>
  <app-main />
</template>

<script>
import AppMain from "@/components/AppMain";

export default {
  name: 'App',
  components: { AppMain },
};
</script>
