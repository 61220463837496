<template>
    <div
        class="w-full"
    >
        <nav class="max-w-8xl mx-auto w-full py-6 px-6 lg:px-8 flex gap-6 items-center justify-between">
            <router-link
                to="/dashboard"
            >
                <object class="w-12 h-auto pointer-events-none" type="image/svg+xml" :data="iconSimonds"></object>
            </router-link>

            <div
                v-if="user != null"
                class="flex gap-4 items-center"
            >
                <p class="text-sm text-right hidden xs:block">
                    <span class=" text-xs text-gray-500">Logged in as</span><br>
                    {{ loggedInAsMessage }}
                </p>

                <sign-out
                    redirect-route-name="Logout"
                >
                    <template v-slot="scope">
                        <button @click="scope.logout" type="button" class="inline-flex gap-2 items-center justify-center rounded-md border border-gray-900 bg-gray-900 bg-opacity-0 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-opacity-10 focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto transition-all duration-300 ease-in-out select-none">
                            <span>Logout</span>
                            <ArrowRightOnRectangleIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                        </button>
                    </template>
                </sign-out>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/20/solid';
import SignOut from "@/components/forms/SignOut";
import iconSimonds from '/src/assets/svg/icon-simonds.svg'
</script>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'SiteNav',
    data: function () {
        return {
            searchOpen: false,
        }
    },
    computed: {
        ...mapGetters({
            user: 'user',
            isUserAdmin: 'isUserAdmin',
            activePage: 'activePage',
            isUserLoggedIn: 'isUserLoggedIn',
            serverTimezone: 'serverTimezone',
            canUserAccessDiscounts: 'canUserAccessDiscounts',
        }),
        loggedInAsMessage() {
            let msg = '';
            msg = this.user.firstName ? msg + ' ' + this.user.firstName : msg;
            msg = this.user.surname ? msg + ' ' + this.user.surname : msg;
            return msg;
        },
        timezone() {
            return this.serverTimezone ? this.serverTimezone : 'local';
        },
    }
}
</script>
