import * as Sentry from '@sentry/vue';

export function initSentry(app, router, config) {
    const sentryConfig = config.sentry;
    if (sentryConfig.key) {
        Sentry.init({
            app,
            environment: config.vueAppEnvironment,
            release: `${config.appName}@${config.vueAppVersion}`,
            dsn: `https://${sentryConfig.key}@${sentryConfig.server}/${sentryConfig.id}`,
            debug: config.vueAppEnvironment  !==  'production',
            tracesSampleRate: config.vueAppEnvironment === 'production' ? 0.2 : 1,
            tracingOptions: {
                trackComponents: true,
            },
            // Vue specific
            logErrors: config.vueAppEnvironment !== 'production',
            attachProps: true,
            attachStacktrace: true
        });
    }
}
