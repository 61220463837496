import config from './config';
import {formatItemizedErrorMessage, getConfig, getStore} from "./helpers";

/** **************************** */
/** ** Discounts Controller ****** */
/** **************************** */
const discountController = [config.apiUrl, 'discount', 'app'].join('/'); // TODO update

const getJobDetailsURL = [discountController, 'framework', 'job'].join('/');
const discountRequestURL = [discountController, 'requests'].join('/');
const createRequestURL = [discountController, 'createRequest'].join('/');
const submitRequestURL = [discountController, 'submitRequest'].join('/');
const approveRequestURL = [discountController, 'approveRequest'].join('/');
const rejectRequestURL = [discountController, 'rejectRequest'].join('/');
const requestOptionsURL = [discountController, 'requestOptions'].join('/');

function log(...values) {
    if (getConfig().isDev) {
        console.log(values);
    }
}

function RequestError(data)
{
    this.status = data.status;
    this.message = data.message;
    this.error = data.error;
}

function checkResponseForErrors(response, status, lookForErrorMessage) {
    // if 401 returned redirect to login
    if (status === 401) {
        const message = 'Failed to authenticate';
        const formatted = formatItemizedErrorMessage(message, response);
        throw new RequestError({message: formatted, status: status, error: response.error});
    } else if (status === 400) {
        // this should be handled per function call not always showing on 400
        // store.dispatch({
        //   type: 'APP/SHOW_FRIENDLY_ERROR_MODAL',
        //   error: message,
        // });
    } else if (status !== 200 && status !== 201) {
        const formatted = formatItemizedErrorMessage("An error was encountered", response);
        throw new RequestError({message: formatted, status: status, error: response.error});
    } else if (!response.success && lookForErrorMessage) {
        let message = response.error;

        const formatted = formatItemizedErrorMessage(message, response);
        throw new RequestError({message: formatted, status: 500, error: response.error});
    }
}

function parseResponse(resp) {
    // If response status is 401 (unauthorised), redirect user back to login screen
    return resp.json();
}

function generateRequestBody(theData) {
    // camel case the request keys
    return theData ? { body: JSON.stringify(theData) } : null;
}

function updateErrorModal(data) {
    return getStore().dispatch('updateError',data);
}

function updateLoader(loader,useLoading){
    if (useLoading) {
        getStore().dispatch('isLoading',loader).then()
    }
}

function performFetch(url, method, body, useFormData) {
    const theAuthToken = getStore().getters.apiAccessToken;

    let headers = {
        Accept: 'application/json',
        ...(theAuthToken ? { Authorization: (getConfig().useBearer ? 'Bearer ' : 'Basic ') + theAuthToken } : {}),
    };

    let init = {};
    if (useFormData) {
        init = { method, headers, body};
    } else {
        init = { method, headers, ...generateRequestBody(body)};
        headers['Content-Type'] = 'application/json';
    }

    return fetch(url, init);
}

function requestAPIAccessToken() {
    return getAPIAccessToken().then((response) => {
        if (response.status === 200) {
            getStore().dispatch('updateAPIAccessToken', response.body.accessToken)
        }
    });
}

function handleResponse(url, response, resolver, rejector, useLoading, lookForErrorMessage) {
    return parseResponse(response).then((parsedResponse) => {
        log(`before processing ${url}`, parsedResponse, response.status);

        checkResponseForErrors(parsedResponse, response.status, lookForErrorMessage);

        let result = {
            body: parsedResponse.data,
            status: response.status,
            ... parsedResponse,
        };

        delete result.data;

        updateLoader(-1,useLoading);
        log(`after processing ${url}`, result);
        resolver(result);
    }).catch((error) => {
        updateErrorModal({message: error.message, status: error.status}).then();
        updateLoader(-1,useLoading);

        rejector({ status: error.status, body: { message: error.message }, error: error.error });
    });
}

function request(method, url, body, useLoading = true, useFormData = false, lookForErrorMessage = true) {
    updateLoader(1, useLoading);

    let promise = new Promise((finalResolve,finalReject) => {
        let fetchPromise = performFetch(url, method, body, useFormData)

        fetchPromise.then((resp) => {
            if (resp.status === 401) {
                let accessTokenFetch = requestAPIAccessToken();
                accessTokenFetch.then(() => {
                    performFetch(url, method, body, useFormData).then((resp2) => {
                        handleResponse(url, resp2, finalResolve, finalReject, useLoading);
                    }).catch((error) => {
                        updateLoader(-1,useLoading);
                        finalReject(error);
                    })
                });

                return;
            }

            handleResponse(url, resp, finalResolve, finalReject, useLoading, lookForErrorMessage);
        }).catch((error) => {
            updateLoader(-1,useLoading);
            const result = {
                status: 500,
                body: {message: error},
                error: error.error
            };
            updateErrorModal({status: result.status, message: result.body.message}).then();
            finalReject(result);
        })
    });

    return promise;
}

// eslint-disable-next-line
export function GET(url, useLoading, params, body, lookForErrorMessage = true) {
    if (params) {
        params = Object.keys(params).reduce((acc,val) => {
            return `${acc}&${val}=${encodeURIComponent(params[val])}`
        }, '').substr(1)
        url = `${url}?${params}`
    }
    return request('GET', url, body, useLoading, false, lookForErrorMessage);
}
// eslint-disable-next-line
export function POST(url, body, useLoading, useFormData = false, lookForErrorMessage = true) {
    return request('POST', url, body, useLoading, useFormData, lookForErrorMessage);
}

// eslint-disable-next-line
export function PUT(url, body, useLoading, useFormData = false, lookForErrorMessage = true) {
    return request('PUT', url, body, useLoading, useFormData, lookForErrorMessage);
}

// eslint-disable-next-line
export function DELETE(url, body, useLoading, lookForErrorMessage = true) {
    return request('DELETE', url, body, useLoading,false, lookForErrorMessage);
}

export function getBackendURL(){
    return getConfig().backendUrl;
}

export function getApiURL(){
    return getConfig().apiUrl;
}

export function getAuthController(){
    return [getApiURL(), 'auth'].join('/');
}

/** **************************** */
/** ** Auth Controller ********* */
/** **************************** */

function getEndpointsWithAppName() {
    let endpoints = [getAuthController()];
    const appName = getConfig().appName;
    if (appName) {
        endpoints.push(appName);
    }
    return endpoints;
}

export function appLogin(params) {
    let endpoints = getEndpointsWithAppName();
    endpoints.push('login');
    return POST(endpoints.join('/'), params);
}

export function appLogout() {
    let endpoints = getEndpointsWithAppName();
    endpoints.push('logout');
    let params = {
        'client_id': getConfig().apiClientID,
        'client_secret': getConfig().apiClientSecret,
        'scope': getConfig().scope
    }
    return POST(endpoints.join('/'), params);
}

export function ssoAppLogin(params) {
    let endpoints = getEndpointsWithAppName();
    endpoints.push('ssoLogin');
    return POST(endpoints.join('/'), params);
}

export function validateToken(params,useLoading = false ) {
    return GET([getApiURL(), 'validateToken'].join('/'),useLoading, params);
}

/** **************************** */
/** ** API token link       **** */
/** **************************** */
export function getAPIAccessToken() {
    let params = {
        'client_id': getConfig().apiClientID,
        'client_secret': getConfig().apiClientSecret,
        'scope': getConfig().scope
    }
    return POST([getApiURL(), 'getAPIAccessToken'].join('/'), params)
}

/** **************************** */
/** ** App Controller  ********* */
/** **************************** */
export function getAppControllerURL(){
    return [getApiURL(), 'apps'].join('/');
}
export function isAppAvailable(params,useLoading = false ) {
    return POST([getAppControllerURL(), 'isAppAvailable'].join('/'), params, useLoading);
}

export function getJobDetailsRequest(jobID) {
    let url = [getJobDetailsURL, jobID].join('/');
    return GET(url);
}

export function getRequestOptions() {
    return GET(requestOptionsURL);
}

export function getDiscountRequests(params) {
    return POST(discountRequestURL, params);
}

export function getDiscountRequest(id) {
    let url = [discountRequestURL, id].join('/');
    return GET(url);
}

export function createDiscountRequest(params) {
    return POST(createRequestURL, params);
}

export function saveDiscountRequest(id, params) {
    return PUT([discountRequestURL, id].join("/"), params)
}

export function deleteDiscountRequest(id) {
    return DELETE([discountRequestURL, id].join("/"));
}

export function submitRequest(id) {
    return PUT([submitRequestURL, id].join("/"));
}

export function approveRequest(id, params) {
    return PUT([approveRequestURL, id].join("/"), params);
}

export function rejectRequest(id, params) {
    return PUT([rejectRequestURL, id].join("/"), params);
}
